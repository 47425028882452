<template>
  <span v-if="this.comingSoon" class="cutoff_label mx-auto">COMING SOON</span>
  <button v-else-if="!fl_settings.require_login || (fl_settings.require_login && isLoggedIn)" :disabled="!hasEffectiveUnitId" @click="addToQuote" class="btn btn-theme ms-3">Quote</button>
  <a v-else :href="'/login'" class="btn btn-theme ms-3 login-quote-btn">LOGIN</a>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import axios from "axios";
import _ from "lodash";

export default {
  name: "AddToQuote",
  props: {
    quant: {
      type: Number,
      default: 1,
    },
    prods: {
      type: Array,
      required: true,
    },
    quote_date: {
      type: Date,
      required: false,
      default: null
    },
    comingSoon: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      fl_settings: JSON.parse(localStorage.fl_settings),
      fllUnitTypes: JSON.parse(localStorage.fllUnitTypes),
    };
  },
  computed: {
    ...mapGetters(["isLoggedIn"]),

    effectiveUnit() {
      const units = {};
      this.prods.forEach((prod) => {
        units[prod.seo_url] = this.calcEffectiveUnit(prod);
      });
      return units;
    },

    hasEffectiveUnitId() {
      let hasUnit = false;
      for (const key in this.effectiveUnit) {
        if (this.effectiveUnit[key].id) {
          hasUnit = true;
          break;
        }
      }
      return hasUnit;
    },
  },
  methods: {
    ...mapMutations(["storeQuote"]),

    calcEffectiveUnit(prod) {
      if (prod.unit_id) {
        return { id: prod.unit_id, name: prod.unit };
      }

      if ("unit_id_dd" in prod && prod.unit_id_dd) {
        const u = _.find(this.fllUnitTypes, { id: prod.unit_id_dd });
        return { id: prod.unit_id_dd, name: u.unit };
      }

      return { id: this.fllUnitTypes[0].id, name: this.fllUnitTypes[0].unit };
    },

    addToQuote() {
      if (!this.quant) {
        this.emitter.emit("a2q", {
          status: 0,
          msg: "Please input the quantity.",
        });
        return;
      }

      if (this.quant < this.prods.increment_quantity || this.quant % this.prods[0].increment_quantity > 0) {
        this.emitter.emit("a2q", {
          status: 0,
          msg: "Quantities need to be in groups of " + this.prods[0].increment_quantity + ". Please try again."
        });
        return;
      }

      this.emitter.emit("showLoader");

      this.prods.forEach((prod) => {
        const effectiveUnit = this.effectiveUnit[prod.seo_url];
        if (effectiveUnit.id) {
          let addDone;
          if (this.isLoggedIn) {
            addDone = axios.post(
              `${process.env.VUE_APP_API_BASE}auth/addToQuote`,
              {
                quantity: this.quant,
                product_id: prod.product_id,
                unit_id: effectiveUnit.id,
                quote_date: this.quote_date
              }, // the data to post
              {
                headers: {
                  "Content-type": "application/json",
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
              }
            );
          } else {
            addDone = Promise.resolve({
              data: {
                error: false,
                message: "Success: You have added to your quote list",
              },
            });
          }

          addDone.then(({ data }) => {
            if (data.error == true) {
              this.emitter.emit("a2q", {
                status: 0,
                msg: data.message,
                level: "error",
              });
            } else {
              this.storeQuote({
                quant: this.quant,
                prodId: prod.product_id,
                unitId: effectiveUnit.id,
                unit: effectiveUnit.name,
                prodImage: prod.image,
                prodName: prod.name,
                prodSeo: prod.seo_url,
                prodTypeId: prod.product_type_id,
                min_increment: this.prods[0].increment_quantity
              });

              this.addToQuoteGAnalytics()

              this.emitter.emit("a2q", {
                status: 1,
                msg: data.message,
                level: "success",
              });
            }
          });
        }
        this.emitter.emit("hideLoader");
      });
    },

    addToQuoteGAnalytics() {
      let productObj = {
        event_category: "quote",
        event_label: this.prods[0].name,
        item_id: this.prods[0].product_id,
        item_name: this.prods[0].name,
        item_category: this.prods[0].category_name,
        item_variant: this.prods[0].color_name,
        quantity: parseInt(this.quant),
        item_category2: this.prods[0].variety_name,
        item_category3: this.prods[0].grade_name          
      };
      this.$gtag.event("add_to_quote", productObj);
    },
  },
};
</script>
<style scoped>
.btn-theme {
  padding: 8px 20px;
  background: transparent;
  border: 2px solid #f37f62;
  border-radius: 0px;
  letter-spacing: 4px;
  color: #f37f62;
  font-weight: 600;
  transition: 300ms all;
  line-height: 18px;
  font-size: 14px;
  font-family: "Raleway", sans-serif;
}
</style>