<template>
  <div class="autocomplete w-auto">
    <input
      type="text"
      :class="style ? 'form-control' : ''"
      @input="onChange"
      v-model="search.name"
      @keydown.down="onArrowDown"
      @keydown.up="onArrowUp"
      @keydown.enter="onEnter"
    />
    <ul
      id="autocomplete-results"
      ref="scrollContainer"
      v-show="isOpen && results.length > 0"
      class="autocomplete-results"
      :style="style ? style : ''"
      :class="style ? 'flowerlistul' : ''"
    >
      <li class="loading" ref="options" v-if="isLoading">Loading results...</li>
      <li
        v-else
        ref="options"
        v-for="(result, i) in results"
        :key="i"
        @click="setResult(result)"
        class="autocomplete-result"
        :class="{ 'is-active': i === arrowCounter }"
      >
        {{ result.name }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "AutoComplete",
  props: {
    eventItemIndex: {
      type: Number,
    },
    recipeIndex: {
      type: Number,
    },
    style: {},
    selected: {},
    items: {
      type: Array,
      required: false,
      default: () => [],
    },
    color: {
      type: String,
      default: "",
    },
    isAsync: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
      results: [],
      search: { value: "" },
      isLoading: false,
      arrowCounter: -1,
    };
  },
  watch: {
    // items: function (value, oldValue) {
    //   //this.search = {}
    //   if (value.length !== oldValue.length) {
    //     this.results = value;
    //     this.isLoading = false;
    //     this.arrowCounter = 1
    //   }
    // },

    results: function () {
      this.arrowCounter = 0;
      // if (this.isOpen)
      // this.fixScrolling()
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
    setTimeout(() => {
      if (this.selected) this.search = this.selected;

      //this.results = this.items;
    }, 50);
  },
  unmounted() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    resetResult() {
      this.search = {};
    },
    setResult(result) {
      this.search = result;
      this.isOpen = false;
      this.$emit("search", {
        value: this.search,
        eventItemIndex: this.eventItemIndex,
        recipeIndex: this.recipeIndex,
      });
    }, 
    filterResults() {
      if (this.search.name.length >= 3) {
        let search_str = "";
        this.results = this.items.filter((item) => {
          let count = 0;
          search_str = this.search.name.toLowerCase();
          let srchArray = Array();
          srchArray = search_str.split(" ");
          let totalResultFound = 0;
          srchArray.forEach((element) => {
            if (totalResultFound > 10) return false;

            if (item.name.toLowerCase().includes(element)) {
              if (this.color) {
                if (
                  item.name.toLowerCase().indexOf(this.color.toLowerCase()) > -1
                )
                  count++;
              } else {
                count++;
              }
            }
          });
          if (count == srchArray.length) {
            totalResultFound++;
            return item;
          }
        });
      }
    },
    fixScrolling() {
      const liH = this.$refs.options.clientHeight;

      this.$refs.scrollContainer.scrollTop = (liH + 1) * this.arrowCounter;
    },
    onChange() {
      this.$emit("input", {
        value: this.search,
        eventItemIndex: this.eventItemIndex,
        recipeIndex: this.recipeIndex,
      });
      if (this.isAsync) {
        this.isLoading = true;
      } else {
        this.filterResults();
        this.isOpen = true;
      }
    },
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.isOpen = false;
        this.arrowCounter = -1;
      }
    },
    onArrowDown(ev) {
      ev.preventDefault();
      if (this.arrowCounter < parseInt(this.results.length - 1)) {
        this.arrowCounter = this.arrowCounter + 1;
      }
      if (this.arrowCounter <= parseInt(this.results.length)) {
        this.fixScrolling();
      }
    },
    onArrowUp(ev) {
      ev.preventDefault();
      if (this.arrowCounter > 0) {
        this.arrowCounter = this.arrowCounter - 1;
        this.fixScrolling();
      }
    },
    onEnter() {
      if (this.results[this.arrowCounter])
        this.search = this.results[this.arrowCounter];
      
      this.$emit("input", {
        value: this.search,
        eventItemIndex: this.eventItemIndex,
        recipeIndex: this.recipeIndex,
      });
      this.isOpen = false;
      this.arrowCounter = -1;
    },
  },
};
</script>

<style>
/* .autocomplete {
    position: relative;
  }

  .autocomplete-results {
    padding: 0;
    margin: 0;
    border: 1px solid #eeeeee;
    height: 120px;
    overflow: auto;
  }

  .autocomplete-result {
    list-style: none;
    text-align: left;
    padding: 4px 2px;
    cursor: pointer;
  }

  .autocomplete-result.is-active,
  .autocomplete-result:hover {
    background-color: #4AAE9B;
    color: white;
  }
  .form-control {
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.flowerlistul {
    padding: 0;
    margin: 0;
    background: #fff;
    box-shadow: 0px 0px 10px #ccc;
    max-height: 400px;
    overflow: auto;
    position: absolute;
    top: 100%;
    width: 715px;
    z-index: 9999;
}
.flowerlistul li {
  display: block;
  padding: 13px;
  border-bottom: 1px solid #ccc;
} */

.autocomplete {
  position: relative;
  width: 130px;
}

.autocomplete-results {
  padding: 0;
  margin: 0;
  border: 1px solid #eeeeee;
  height: 200px;
  overflow: auto;
  width: 100%;
}

.autocomplete-result {
  list-style: none;
  text-align: left;
  padding: 12px 6px !important;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}

.autocomplete-result.is-active,
.autocomplete-result:hover {
  background-color: #4aae9b;
  color: white;
}
</style>