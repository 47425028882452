<template>
  <a
    class="single-wishlist-btn mt-2 d-block fr-wish"
    href="#"
    @click.prevent="addToWishlist"
    @mouseenter="hoverIn"
    @mouseleave="hoverOut"
    ><span v-show="hovered"
      ><i class="fa-solid fa-heart heart-icon" aria-hidden="true"></i
    ></span>
    <span v-show="!hovered"
      ><i class="fa-thin fa-heart heart-icon" aria-hidden="true"></i
    ></span>
    <span v-if="showText"> Add to Wishlist</span></a
  >
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";

export default {
  name: "AddToWishlist",
  props: {
    prod: {
      type: Object,
      required: true,
    },
    showText: {
      default: false,
    },
  },
  data() {
    return {
      hovered: false,
      isExist: false,
      //desiredQuant: this.min,
    };
  },
  mounted() {
    let currentWishlistItems = this.getWishListItems()
    setTimeout (() => {
      if (currentWishlistItems.indexOf(this.prod.product_id.toString()) != -1) {
        this.isExist = true
        this.hovered = true
      }  
    }, 300)
    

  },
  methods: {
    //...mapMutations({ addToCart_s: "addToCart" }),
    ...mapActions(["addToWishList"]),
    ...mapGetters(["getWishListItems"]),

    addToWishlist() {
      this.emitter.emit("showLoader");
      axios
        .post(
          `${process.env.VUE_APP_API_BASE}auth/addToWishlist`,
          {
            product_id: this.prod.product_id,
          }, // the data to post
          {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then(({ data }) => {
          let type = "";
          if (data.error == true) {
            type = "error";
          } else {
            this.addToWishlistGAnalytics()
            type = "success";
            this.isExist = true
            this.hovered = true
            this.emitter.emit("addToWishlist", {
              status: 1,
              msg: data.message,
              level: "success",
            });
          }
          this.emitter.emit("displayFlash", {
            message: data.message,
            level: type,
          });
          this.emitter.emit("hideLoader");
        });
    },
    addToWishlistGAnalytics() {
      let productObj = {
        event_category: 'wishlist',
        event_label: 'add to wishlist',
        item_id: this.prod.product_id,
        item_name: this.prod.name,
        item_category: this.prod.category_name,
        item_category2: this.prod.variety_name,
        item_variant: this.prod.color_name,
        item_category3: this.prod.grade_name,
      };
      this.$gtag.event("add_to_wishlist", productObj);
    },

    hoverIn() {
      this.hovered = true;
    },

    hoverOut() {
      if (!this.isExist)
      this.hovered = false;
    },
    watch: {
      "isExist": function () {
        if (this.triggerWatch) {
          this.filters.pageNumb = 1;
          localStorage.setItem("perPage", this.filters.perPage);
          this.fetchProducts();
        }
      }
    }
  },
};
</script>
<style scoped>
.fr-wish {
  float: right;
  position: absolute;
  right: 7px;
  top: 11px;
  color: #f37f62;
}
a.single-wishlist-btn .fa-heart {
  -webkit-text-fill-color: white;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #f37f62;
}
a.single-wishlist-btn .fa-heart {
  -webkit-text-fill-color: #f37f62;
}
a.single-wishlist-btn {
  text-decoration: none;
}
</style>