export default class Validator {
	fields = {};
	rules = {};
	errors = {};

	constructor(data) {
		for (let field in data) {
			let value = '';
			if ('value' in data[field]) {
				value = data[field].value;
			}
			this.fields[field] = value;

			if ('rules' in data[field]) {
				this.rules[field] = data[field].rules;
				this.errors[field] = [];
			}

			Object.defineProperty(this, field, {
				get: function() {
					return this.fields[field];
				},

				set: function(val) {
					this.fields[field] = val;
					this.errors[field] = [];
				},
			});
		}
	}

	validate() {
		return new Promise((resolve, reject) => {
			let hasError = false;
			for (let field in this.rules) {
				this.errors[field] = [];

				for (let r of this.rules[field]) {
					r = r.split(':');

					const rule = r[0];
					if (rule === 'optional') {
						console.log('')
					}
					else if (rule === 'required') {
						if (this.fields[field] === '') {
							this.errors[field].push('This field is required');
							hasError = true;
						}
					} else if (rule === 'email') {
						if (
							this.fields[field] &&
							!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(
								this.fields[field]
							)
						) {
							this.errors[field].push('Please provide valid email');
							hasError = true;
						}
					} else if (rule === 'url') {
						if (
							this.fields[field] &&
							!/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g.test(this.fields[field]) // eslint-disable-line
						) {
							this.errors[field].push('Please provide valid url');
							hasError = true;
						}
					} else if (rule === 'numeric') {
						if (this.fields[field] && !/^\d+$/.test(this.fields[field])) {
							this.errors[field].push('Please provide numeric input');
							hasError = true;
						}
					} else if (rule === 'float') {
						if (this.fields[field] && !/^[0-9]\d*((\.\d{1,2})?)$/.test(this.fields[field])) {
							this.errors[field].push('Please provide upto two place decimal input');
							hasError = true;
						}
					} else if (rule === 'requiredIf') {
						const ruleCondition = r[1].split(','),
							conditionOnField = ruleCondition[0],
							condition = ruleCondition[1],
							conditionValue = ruleCondition[2];

						let conditionMet = false;
						switch (condition) {
							/* case '>':   return post > value;
							case '<':   return post < value;
							case '>=':  return post >= value;
							case '<=':  return post <= value;
							case '==':  return post == value;
							case '!=':  return post != value;
							case '===': return post === value;
							case '!==': return post !== value; */
							case '!=':  //return post != value:
								conditionMet = this.fields[conditionOnField] != conditionValue;
								break;
							case '=':
								conditionMet = this.fields[conditionOnField] == conditionValue;
								break;
						}

						if (conditionMet && this.fields[field] === '') {
							this.errors[field].push('This field is required');
							hasError = true;
						}
					} else if (rule === 'max') {
						if (this.rules[field].includes('upload')) {
							if (this.fields[field].length > r[1]) {
								this.errors[field].push(`Please upload max ${r[1]} file(s)`);
								hasError = true;
							}
						}
					} else if (rule === 'allowed') {
						if (this.rules[field].includes('upload')) {
							for (const file of this.fields[field]) {
								if (!r[1].includes(file.type)) {
									this.errors[field].push(`Please upload valid file`);
									hasError = true;
								}
							}
						}
					}
				}
			}

			if (!hasError) {
				resolve();
			} else {
				// console.log(this.errors.length)
				reject({ validation: false, message: 'Validation Fails' });
			}
		});
	}

	setUploadValue(e, field) {
		this.fields[field] = e.target.files || e.dataTransfer.files;
	}

	hasError(field) {
		return !!this.errors[field].length;
	}

	getErrors(field) {
		return this.errors[field].join(', ');
	}
}
