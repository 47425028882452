export default class Dater {
	static getAmPm(date) {
		date = this._toDateObj(date);
		const hours = date.getHours();
		return hours >= 12 ? 'PM' : 'AM';
	}

	static getMinutesPadded(date) {
		date = this._toDateObj(date);
		return this._padIt(date.getMinutes());
	}

	static getHoursPadded(date) {
		date = this._toDateObj(date);
		return this._padIt(date.getHours());
	}

	static getUiHours(date) {
		date = this._toDateObj(date);
		let hours = date.getHours();
		if (hours > 12) hours = hours - 12;
		return this._padIt(hours);
	}

	static getUiHours12(date) {
		date = this._toDateObj(date);
		let hours = date.getHours();
		if (hours > 12) hours = hours - 12;
		if (hours == 0) hours = 12;
		return hours
	}

	static getDayWithOrdinal(date) {
		return this.ordinalSuffixOf(this.getDay(date));
	}

	static ordinalSuffixOf(i) {
		var j = i % 10,
			k = i % 100;
		if (j == 1 && k != 11) {
			return i + "st";
		}
		if (j == 2 && k != 12) {
			return i + "nd";
		}
		if (j == 3 && k != 13) {
			return i + "rd";
		}
		return i + "th";
	}

	static getDay(date) {
		date = this._toDateObj(date);
		return date.getDate();
	}

	static getDayPadded(date) {
		date = this._toDateObj(date);
		return this._padIt(this.getDay(date));
	}

	static getMonthPadded(date) {
		date = this._toDateObj(date);
		return this._padIt(date.getMonth() + 1);
	}

	static getMonthName(date) {
		const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

		date = this._toDateObj(date);
		return months[date.getMonth()];
	}

	static getUiTime(date) {
		date = this._toDateObj(date);
		return this.getUiHours(date) + ':' + this.getMinutesPadded(date) + ' ' + this.getAmPm(date);
	}

	static getUiTime12(date) {
		date = this._toDateObj(date);
		return this.getUiHours12(date) + ':' + this.getMinutesPadded(date) + ' ' + this.getAmPm(date);
	}

	static getUiDate(date) {
		date = this._toDateObj(date);
		return this.getMonthPadded(date) + '-' + this.getDayPadded(date) + '-' + date.getFullYear();
	}

	static getUiDatetime(date) {
		date = this._toDateObj(date);
		return this.getUiDate(date) + ' ' + this.getUiTime(date);
	}

	static toDbDate(date) {
		date = this._toDateObj(date);
		return date.getFullYear() + '-' + this.getMonthPadded(date) + '-' + this.getDay(date);
	}

	static toDbDateTime(dateTime) {
		dateTime = this._toDateObj(dateTime);
		return (
			this.toDbDate(dateTime) + ' ' + this.getHoursPadded(dateTime) + ':' + this.getMinutesPadded(dateTime) + ':' + this._padIt(dateTime.getSeconds())
		);
	}

	static getDaysArray(s, e) {
		for (var a = [], d = new Date(s); d <= new Date(e); d.setDate(d.getDate() + 1)) {
			a.push(new Date(d));
		}
		return a;
	}

	static getMaxOf(date1, date2) {
		date1 = this._toDateObj(date1);
		date2 = this._toDateObj(date2);
		return this._toDateObj(Math.max(date1, date2));
	}

	static getMinOf(date1, date2) {
		date1 = this._toDateObj(date1);
		date2 = this._toDateObj(date2);
		return this._toDateObj(Math.min(date1, date2));
	}

	static _toDateObj(date) {
		if (typeof date.getMonth !== 'function') {
			return new Date(date);
		}

		return date;
	}

	static _padIt(date) {
		return String(date).padStart(2, 0);
	}
}
